import React, { useEffect, useState } from 'react';
import { setItems } from '../../../../redux/Reducer';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../../../utils/http';
import ItemsView from '../../../../components/store/shop/items/ItemsView';
import { message } from 'antd';

const ItemsHOC = () => {
	const { items, user } = useSelector(state => state);
	const { get, patch, put } = api();
	const dispatch = useDispatch();

	const initialState = {
		name: '',
		category: '',
		price: '',
		description: '',
		available: true,
		files: [],
		modifiers: [],
	};

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState(initialState);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!user?.shop || open) {
			return;
		}
		get(`/items`, { pageNumber, pageSize })
			.then(response => {
				if (!response) {
					return;
				}
				console.log('deee', response.data.data);
				dispatch(
					setItems(response.data.data),
					// .map(it => ({ ...it, price: it.price / 100 }))
				);
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {
				message.error(err.response.data.message);
			});
	}, [pageNumber, pageSize, open, user]);

	const onFinish = async () => {
		try {
			const p = Number(`${item.price}`?.replaceAll(',', '.'));
			if (!isFinite(p)) {
				message.error('Η τιμή πρέπει να είναι νούμερο');
				return;
			}

			const data = {
				name: item.name,
				shop: user?.shop || undefined,
				language: user.language,
				category: item.category,
				price: Math.round(p * 100),
				available: !!item.available,
				files: !item?.files ? [] : item.files?.map(file => file.id),
				// files: item.id
				// 	? item.files?.[0]?.id
				// 		? item.files?.map(file => file.id)
				// 		: item?.files
				// 	: item.files?.map(file => file.id) || [],
				modifiers: !item.modifiers
					? []
					: item.modifiers?.[0]?.id
						? item.modifiers.map(it => it.id)
						: item.modifiers,
				description: item.description || '',
			};
			console.log('da', data);

			const action = item.id ? put : patch;

			const res = await action(`/items/${item?.id || ''}`, data);
			console.log('da', 'da');

			setOpen(false);
			message.success('Επιτυχία!');
			setItem({ initialState });
		} catch (err) {
			message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');

			console.log(err);
		}
	};

	return (
		<ItemsView
			data={items}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			open={open}
			setOpen={setOpen}
			user={user}
		/>
	);
};

export default ItemsHOC;
