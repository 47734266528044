import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModifiers } from '../../../../redux/Reducer';
import api from '../../../../utils/http';
import ModifierView from '../../../../components/store/shop/modifiers/ModifierView';
import { message } from 'antd';

const ModifiersHOC = () => {
	const { modifiers, user } = useSelector(state => state);
	const { get, patch, put } = api();
	const dispatch = useDispatch();

	const [pageNumber, setPageNumber] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [totalPages, setTotalPages] = useState(0);
	const [item, setItem] = useState({
		options: [],
	});
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (!user?.shop || open) {
			return;
		}
		get(`/modifiers`, {
			pageNumber,
			pageSize,
		})
			.then(response => {
				response && dispatch(setModifiers(response.data.data));
				setTotalPages(response.data.totalPages);
			})
			.catch(err => {
				message.error(err.response.data.message);
			});
	}, [pageNumber, pageSize, open, user]);

	const onFinish = async () => {
		try {
			if (!item?.name) {
				message.error('Το όνομα είναι κενό');
				return;
			}

			const action = item?.id ? put : patch;

			const data = {
				name: item.name,
				shop: user.shop,
				language: user.language,
				type: item.type ? 1 : 0,
				required: !!item.required,
				// options: item.options?.[0]?.id ? item.options.map(option => option.id) : [],
				options: !item.options
					? []
					: item.options?.[0]?.id
						? item.options.map(it => it.id)
						: item.options,
			};

			const res = await action(`/modifiers/${item?.id || ''}`, data);

			setOpen(!open);
			message.success(`Επιτυχία!`);
			setItem({});
		} catch (err) {
			console.log(err);
			message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
		}
	};

	return (
		<ModifierView
			data={modifiers}
			pageNumber={pageNumber}
			setPageNumber={setPageNumber}
			pageSize={pageSize}
			setPageSize={setPageSize}
			totalPages={totalPages}
			item={item}
			setItem={setItem}
			onFinish={onFinish}
			open={open}
			setOpen={setOpen}
			user={user}
		/>
	);
};

export default ModifiersHOC;
